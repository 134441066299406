import React from 'react'

import { BasicTemplate } from '../components/templates/basicTemplate'
import { ContactPageTemplate } from '../components/templates/contactPageTemplate'
import { useBasicSettings } from '../hooks/useBasicSettings'
import { useContactPageSettings } from '../hooks/useContactPageSettings'

const ContactPage = () => {
  const { basicSettings } = useBasicSettings()
  const { contactPageSettings } = useContactPageSettings()

  return (
    <BasicTemplate basicSettings={basicSettings}>
      <ContactPageTemplate contactPageSettings={contactPageSettings} />
    </BasicTemplate>
  )
}

export default ContactPage
