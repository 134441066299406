import React from 'react'
import styled from 'styled-components'

import { Center as _Center } from '../layout'
import { ContactPageSettings } from '../../types/contactPageSettings'

interface Props {
  contactPageSettings: ContactPageSettings
}

const Wave = ({ color }: { color: string }) => {
  return (
    <WaveSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 120" color={color}>
      <path d="M0,256L80,240C160,224,320,192,480,202.7C640,213,800,267,960,272C1120,277,1280,235,1360,213.3L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
    </WaveSvg>
  )
}

export const ContactPageTemplate = ({ contactPageSettings }: Props) => {
  return (
    <>
      <Wave color={contactPageSettings.backGroundColor} />
      <GreenBG bgColor={contactPageSettings.backGroundColor}>
        <Center>
          <H2>GET IN TOUCH</H2>
          <FormWrapper>
            <Message dangerouslySetInnerHTML={{ __html: contactPageSettings.message }}></Message>
            <Form
              inputBackGroundColor={contactPageSettings.inputBackGroundColor}
              submitButtonColor={contactPageSettings.submitButtonColor}
              name="contact"
              method="POST"
              data-netlify="true"
              data-netlify-recaptcha="true"
              action="/thankyou"
            >
              <input type="hidden" name="form-name" value="contact" />
              <input placeholder={'name'} type={'text'} name={'name'} required />
              <input placeholder={'email'} type={'email'} name={'email'} required />
              <input placeholder={'subject'} type={'text'} name={'subject'} required />
              <textarea placeholder={'type your message here...'} name={'message'} required />
              <div data-netlify-recaptcha="true" />
              <button type={'submit'}>Submit</button>
            </Form>
          </FormWrapper>
        </Center>
      </GreenBG>
    </>
  )
}

const WaveSvg = styled.svg`
  margin-top: -40px;

  path {
    fill: ${({ color }) => color};
    fill-opacity: 1;
    transform: translateY(-156px);
  }
`

const GreenBG = styled.div<any>`
  width: 100%;
  background-color: ${(props: any) => props.bgColor};
  padding: 60px 0 100px 0;
  margin-bottom: 20px;
`

const Center = styled((props) => <_Center {...props} />)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const H2 = styled.h2`
  color: ${({ theme }) => theme.color.darkGrey};
  font-size: ${({ theme }) => theme.font.size.xxl};
  font-weight: ${({ theme }) => theme.font.weight['400']};
`

const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 50px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: column;
  }
`

const Message = styled.div`
  flex: 4;
  padding-right: 70px;

  letter-spacing: 0.013rem;
  color: ${({ theme }) => theme.color.darkGrey};
  font-weight: ${({ theme }) => theme.font.weight['300']};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    flex: 1;
    text-align: center;
    padding-right: 0;
    padding-bottom: 40px;
  }
`

const Form = styled.form<any>`
  flex: 6;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  color: white;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    flex: 1;
    width: 100%;
  }

  input {
    background-color: ${(props: any) => props.inputBackGroundColor};
    height: 42px;
    width: 100%;
    padding-left: 15px;

    outline: none;
    border: none;
    border-bottom: 2px white solid;

    :last-of-type {
      grid-column: 2 span;
    }
  }

  textarea {
    grid-column: 2 span;
    background-color: ${(props: any) => props.inputBackGroundColor};
    height: 150px;
    padding: 15px;

    outline: none;
    border: none;
    border-bottom: 2px white solid;
    resize: none;

    ::placeholder {
      color: white;
    }
  }

  button {
    grid-column: 2 span;
    background-color: ${(props: any) => props.submitButtonColor};
    height: 42px;
    outline: none;
    border: none;
  }
`
