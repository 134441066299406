import { useStaticQuery, graphql } from 'gatsby'
import { ContactPageSettings } from '../types/contactPageSettings'

export const useContactPageSettings = (): { contactPageSettings: ContactPageSettings } => {
  const data = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/contactPage.md/" }) {
          frontmatter {
            backGroundColor
            inputBackGroundColor
            submitButtonColor
            formTitle
            message
          }
        }
      }
    `
  )

  return { contactPageSettings: data.markdownRemark.frontmatter }
}
